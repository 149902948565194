
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCusg4tJNVkIxpaY1ltQ_xG8176UEdBsB8",
  authDomain: "productji.firebaseapp.com",
  projectId: "productji",
  storageBucket: "productji.appspot.com",
  messagingSenderId: "287638424277",
  appId: "1:287638424277:web:cf2afdb322909cbb93705c",
  measurementId: "G-ETX9RM2VSG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;