import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ShowImage from './ShowImage'


const Card = ({ product }) => {
    const navigate = useNavigate();
    return (
        <div className="col-md-6 mb-3 col-lg-3">
            <Link to={`/product/${product._id}`} style={{color: "black",textDecoration: "none"}}>
                <div className="carrd" >
                    <div className="card-body" >
                        {/* <ShowImage item={product} url="product" /> */}
                        <div className="mb-3 d-flex justify-content-center" >
                            <img src={product.photo[0]} alt={product.name} style={{ maxHeight: '200px', maxWidth: '100%' }} />
                        </div>
                        <div >
                            <h6>{product.name.substring(0, 25) + "..."}</h6>
                            <p style={{ color: "gray" }}>by - {product.store.name}</p>
                            <h6>₹{product.price}</h6>
                            <p>{product.description.substring(0, 50) + "..."}</p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
        // </div>
    )
}

export default Card
