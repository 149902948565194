import React, { useEffect, useState } from 'react'
import { CommonLoading } from 'react-loadingg'
import { useParams } from 'react-router-dom'
import { getProductsByShop, getShop } from './apiCore'
import Card from './Card'
import Layout from './Layout'

const Shop = () => {

    let params = useParams()
    const [shop, setShop] = useState({})
    const [products, setProducts] = useState([])
    const [error, setError] = useState('')

    useEffect(() => {
        const shopId = params.shopId;
        loadShop(shopId);
        loadProductsByShop(shopId);
    }, [params])

    const loadShop = shopId => {
        getShop(shopId).then(data => {
            if (data.error) {
                setError(data.error)
            } else {
                setShop(data)
                console.log(data);
            }
        })
    }

    const loadProductsByShop = (shopId) => {
        getProductsByShop(shopId).then(data => {
            if (data.error) {
                setError(data.error)
            } else {
                setProducts(data)
                console.log(data);
            }
        })
    }

    return (shop === {} ? (<div className="container" style={{height: "70vh"}}><CommonLoading></CommonLoading></div>)
    : (
        <Layout>
            <div className="row">
                <div className="col-3 d-flex justify-content-center align-items-center">
                    {shop.photo ? <img class="rounded-circle img-fluid" style={{maxWidth: "60%"}} alt="100x100" src={shop.photo} data-holder-rendered="true" /> : <img class="rounded-circle img-fluid" alt="100x100" style={{maxWidth: "60%"}} src="https://cdn2.iconfinder.com/data/icons/facebook-ui-colored/48/JD-15-512.png" data-holder-rendered="true" />}
                </div>

                <div className="col">
                    <h3>{shop?.name}</h3>
                    <p>{shop?.about}</p>
                    <p>{shop?.city}</p>
                    <p>{shop?.phone}</p>
                    <p>{shop?.openTiming}</p>
                    <p>{shop?.address}</p>
                    <a href={shop?.link} target="_blank">{shop?.link}</a>
                </div>
            </div>

            <hr />

            {products.length === 0 ? (<h1 className="mb-4 mt-4">No Products Found</h1>) : (<div className='mt-4'>
                <h1 className="mb-4 mt-4">Products</h1>
                <div className="row" >
                    {products.map((product, i) => (
                        <Card key={i} product={product} />
                    ))}
                </div>
            </div>)}
        </Layout>
    ))
}

export default Shop