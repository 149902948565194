import React, { useEffect, useState } from 'react'
import { getProducts } from './apiCore'
import Card from './Card'
import Layout from './Layout'
import Search from './Search'
import { CommonLoading } from 'react-loadingg';

const Home = () => {

    const [productsBySell, setProductsBySell] = useState([])
    const [productsByArrival, setProductsByArrival] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false)

    const loadProductsBySell = () => {
        setLoading(true);
        getProducts().then(data => {
            if (data.error) {
                console.log(data.error);
            }
            else {
                setProductsBySell(data)
                setLoading(false)
            }
        })
    }

    const loadProductsByArrival = () => {
        setLoading(true)
        getProducts().then(data => {
            if (data.error) {
                setError(data.err)
            }
            else {
                setProductsByArrival(data)
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        loadProductsByArrival()
        // loadProductsBySell()
    }, [])

    if (loading == true) {
        return <div className="container" style={{ height: "70vh" }}><CommonLoading></CommonLoading></div>
    }

    return (
        <div >
            {productsByArrival.length != 0 ? <Layout title="" description="" className="container">
                <h1 className="mb-4">Just Arrived</h1>
                <div className="row" >
                    {productsByArrival.map((product, i) => (
                        <Card key={i} product={product} />
                    ))}
                </div>
            </Layout> : <div className="container" style={{ height: "30vh" }}><h1>Oops, that did not return any results :( </h1> </div>}
            
        </div>
    )
}

export default Home
