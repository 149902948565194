import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { listRelated, read } from './apiCore'
import Layout from './Layout'
import { FacebookShareButton, FacebookIcon, EmailShareButton, EmailIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon, TelegramShareButton, TelegramIcon } from "react-share";

import { CommonLoading } from 'react-loadingg';

// import Carousel from './carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Helmet from 'react-helmet'
import { Share } from '@material-ui/icons'
const Product = () => {

    let params = useParams()
    const [product, setProduct] = useState({})
    const [related, setRelated] = useState([])
    const [error, setError] = useState('')

    const loadSingleProduct = productId => {
        read(productId).then(data => {
            if (data.error) {
                setError(data.error)
            } else {
                setProduct(data)
                console.log(data);
            }
        })
    }

    useEffect(() => {
        const productId = params.productId
        loadSingleProduct(productId);
    }, [params])

    if (product.photo === undefined) {
        document.title = "ProductJi"
        return <div className="container" style={{ height: "70vh" }}><CommonLoading></CommonLoading></div>
    }

    const showShare = () => {
        if (navigator.share) {
            return <Share style={{ cursor: "pointer" }} onClick={() => {
                navigator.share({
                    url: window.location,
                    title: product.name,
                    text:  `Check out this product on ProductJi: ${product.name}`
                })
            }} />
        }
    }

    document.title = product.name
    document.description = product.description
    return (
        <div className="container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{product.name}</title>
                <meta property="og:title" content={product.name.substring(0, 25)} />
                <meta property="og:type" content="ecommerce" />
                <meta property="og:site_name" content="ProductJi"></meta>
                {/* <meta property="og:image" content={product.photo[0]} /> */}
                <meta property="og:url" content={window.location} />
                <meta property="og:description" content={product.description.substring(0, 60)} />
            </Helmet>
            <div className="container mt-4">
                <div className="container" id="product-section">
                    <div className="row">
                        <div className="col-md-6">
                            {product.photo !== undefined &&
                                <Carousel >
                                    {product.photo.map(image => (
                                        <div>
                                            <img src={image} alt={product.name}
                                                className="mb-3" style={{ maxHeight: '100%', maxWidth: '100%' }} />
                                        </div>
                                    ))}
                                </Carousel>}
                        </div>

                        <div className="col-md-6">
                            <h3 className='mb-3'>{product.name}</h3>
                            <h2 className='mb-4'>₹{product.price}</h2>
                            <p className='mb-4'>{product.description}</p>
                            <h6>Category: {product.category && (
                                product.category.map(c => {
                                    return `#${c} `
                                })
                            )}</h6>
                            {/* <p>Created {moment(product?.createdAt).fromNow()}</p> */}

                            <a href={product.link} target="_blank">
                                <button className='btn btn-warning mb-4 mt-4' style={{ borderRadius: '5px' }}>Buy from shop</button>
                            </a>
                            <div >
                                <FacebookShareButton
                                    url={window.location}
                                    quote={product.description}
                                    hashtag="#ShopOnProductJi"
                                    className
                                    style={{ marginRight: "10px" }}>
                                    <FacebookIcon size={36} />
                                </FacebookShareButton>

                                <EmailShareButton
                                    body={`I think you'd like this product \n ${product.name} by ${product.store.name}. \n`}
                                    url={window.location}
                                    separator="Learn more at: "
                                    subject="Check out this product on ProductJi"
                                    className
                                    style={{ marginRight: "10px" }}>
                                    <EmailIcon size={36} />
                                </EmailShareButton>

                                <WhatsappShareButton
                                    url={window.location}
                                    title={`Check out this product on ProductJi. \n ${product.name} by ${product.store.name}. \n`}
                                    separator='Learn more at: '
                                    className
                                    style={{ marginRight: "10px" }}>
                                    <WhatsappIcon size={36} />
                                </WhatsappShareButton>
                                <TwitterShareButton
                                    url={window.location}
                                    title={`Check out this product on ProductJi. \n ${product.name} by ${product.store.name}. \n`}
                                    hashtag="#ProductJi"
                                    className
                                    style={{ marginRight: "10px" }}>
                                    <TwitterIcon size={36} />
                                </TwitterShareButton>
                                <TelegramShareButton
                                    url={window.location}
                                    title={`Check out this product on ProductJi. \n ${product.name} by ${product.store.name}. \n`}
                                    className
                                    style={{ marginRight: "10px" }}>
                                    <TelegramIcon size={36} />
                                </TelegramShareButton>
                                {
                                    showShare()
                                }
                            </div>
                            <hr />
                            <div className="mt-4">
                                <h2>Seller</h2>
                                <div className="row mt-4">
                                    <div className="col-md-3 col-sm-6 mb-4 justify-content-center align-items-center mx-auto">

                                        {product.store.photo ? <img class="rounded-circle img-fluid" alt="100x100" src={product.store.photo} data-holder-rendered="true" /> : <img class="rounded-circle img-fluid" alt="100x100" src="https://cdn2.iconfinder.com/data/icons/facebook-ui-colored/48/JD-15-512.png" data-holder-rendered="true" />}

                                    </div>
                                    <div className="col-md-9 col-sm-12">
                                        <p>Name: {product.store.name}</p>
                                        <p>City: {product.store.city}</p>
                                        {product.store.address && <p>Address: {product.store.address}</p>}
                                        {product.store.about && <p>{product.store.about}</p>}
                                        {product.store.phone && <p>Phone: {product.store.phone}</p>}
                                        {/* {product.store.photo && <p>Photo: {product.store.photo}</p>} */}
                                        {product.store.openTiming && <p>Open At: {product.store.openTiming}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* <div className="row">
                    {related.length > 0 && <h1>Related Products</h1>}
                    <div className="col-md-12">
                        {related.map((product, i) => (
                            <Card product={product} key={i} />
                        ))}
                    </div>
                </div> */}
                </div>
            </div>
        </div >
    )
}

export default Product
