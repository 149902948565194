import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { isAdmin, isAuthenticated, signout } from '../auth'
import Search from './Search'



const Menu = () => {

    const navigate = useNavigate()

    return (

        <div className='bg-primary sticky-top' >
            <nav class="navbar navbar-expand-lg navbar-dark">
                <Link class="navbar-brand" to="/">ProductJi</Link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse w-100 order-1 order-md-0" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item active">
                            <Link class="nav-link" to="products">Products</Link>
                        </li>
                        <li class="nav-item active">
                            <Link class="nav-link" to="/shops">Shops</Link>
                        </li>
                    </ul>
                </div>

                <div class="navbar-collapse collapse w-100 order-2 order-md-0" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item active">
                            <Link class="nav-link" to="/signup">Become a Seller</Link>
                        </li>
                    </ul>
                </div>
            </nav>
            < Search />
        </div >
    )
}

export default Menu

{/* <ul className="nav nav-active align-items-center mb-0">
    <a to="/" style={{ "color": "white" }} className="navbar-brand ml-3">Product Ji</a>

    {/* {!isAuthenticated() && (
        <Fragment>
            <li className="nav-item">
                <Link className="nav-link" to="/signin">Sign In</Link>
            </li>


            <li className="nav-item">
                <Link className="nav-link" to="/signup">Sign Up</Link>
            </li>
        </Fragment>
    )} */}

{/* {isAuthenticated() && (
        <Fragment>
            <li className="nav-item">
                <span className="nav-link" style={{ cursor: "pointer", color: "#ffffff" }}
                    onClick={() =>
                        signout(() => {
                            navigate("/");
                        })
                    }>Sign Out</span>
            </li>
        </Fragment>
    )} */}

{/* </ul>  */}