import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet';
import { CommonLoading } from 'react-loadingg';
import { getProducts } from './apiCore'
import Card from './Card';
import Layout from './Layout'

const Products = () => {

    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');

    const loadProducts = () => {
        getProducts().then(data => {
            if (data.error) {
                setError(data.err)
            }
            else {
                setProducts(data)
            }
        })
    }

    useEffect(() => {
        loadProducts()
        // loadProductsBySell()
    }, [])

    return products.length === 0 ? <div className="container" style={{ height: "70vh" }}><CommonLoading></CommonLoading></div> : (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ProductJi</title>
                <meta property="og:title" content="ProductJi" />
                {/* <meta property="og:image" content={``} /> */}
                <meta property="og:description" content="Product Ji helps you shop from small businesses and provides budding businesses a marketplace without tampering with their flexibilty." />
            </Helmet>
            <Layout title="" description="" className="container">
                {/* <h1 className="mb-4">Just Arrived</h1> */}
                <div className="row" >
                    {products.map((product, i) => (
                        <Card key={i} product={product} />
                    ))}
                </div>
            </Layout>
        </div>
    )
}

export default Products