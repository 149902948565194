import {API} from "../config"
import queryString from "query-string"

export const getProducts = () => {
    return fetch(`${API}/products`,{
        method: "GET",
    })
    .then(res => {
        return res.json()
        // console.log(res);
    })
    .catch(err => {
        console.log(err);
    })
}

// export const getCategories = () => {
//     return fetch(`${API}/categories`,{
//         method: "GET"
//     })
//     .then(response => {
//         return response.json()
//     })
//     .catch(err => {
//         console.log(err);
//     })

// }

export const getFilteredProducts = (skip,limit,filters = {}) => {

    const data = {limit,skip,filters}

    return fetch(`${API}/products/by/search`,{
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    })

}

export const list = (params) => {
    const query = queryString.stringify(params)
    return fetch(`${API}/products/search?${query}`,{
        method: "GET"
    })
    .then(response => {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    })
}

export const read = (productId) => {
    return fetch(`${API}/product/find/${productId}`,{
        method: "GET"
    })
    .then(response => {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    })
}

export const listRelated = (productId) => {
    return fetch(`${API}/products/related/${productId}`,{
        method: "GET"
    })
    .then(response => {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    })
}

export const getAllStores = () => {
    return fetch(`${API}/stores`,{
        method: "GET"
    })
    .then(response => {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    })
}

export const getShop = (shopId) => {
    return fetch(`${API}/stores/${shopId}`,{
        method: "GET"
    })
    .then(response => {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    })
}

export const getProductsByShop = (shopId) => {
    return fetch(`${API}/products/${shopId}`,{
        method: "GET"
    })
    .then(response => {
        return response.json()
    })
    .catch(err => {
        console.log(err);
    })
}
