import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { CommonLoading } from 'react-loadingg';
import { useSearchParams, useLocation } from 'react-router-dom';
import { list } from './apiCore';
import Card from './Card'
import Layout from './Layout'

const SearchResults = () => {

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const q = query.get('search')

    const [results, setResults] = useState([]);
    const [searched, setSearched] = useState(false);

    useEffect(() => {
        searchData()
    }, [search])

    const searchMessage = (results = []) => {
        if (searched) {
            if (results.length > 0) {
                return `Found ${results.length} product(s)`
            }

            if (results.length < 1) {
                return `No products found :(`
            }
        }
        else{
            return <div className="container" style={{height: "70vh"}}><CommonLoading></CommonLoading></div>
        }
    }

    const searchData = () => {
        // console.log(search, category);
        if (search) {
            list({ search: q }).then(
                response => {
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        setResults(response);
                    }
                }
            ).then(() => {
                setSearched(true);
            })
        }
    };

    return (
        <Layout title="" description="" className="container">
            <h2>{searchMessage(results)}</h2>
            <div className="row" >
                {results.map((product, i) => (
                    <Card key={i} product={product} />
                ))}
            </div>
        </Layout>
    )
}

export default SearchResults