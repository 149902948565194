import React, { useEffect, useState } from 'react'
import { getAllStores, getFilteredProducts } from './apiCore';
import Checkbox from './Checkbox';
import Layout from './Layout'
import { prices } from "./fixedPrices"
import RadioBox from './RadioBox';
import Card from "./Card"
import { Helmet } from "react-helmet"
import ShopsCard from './ShopsCard';
import { CommonLoading } from 'react-loadingg';
const Shops = () => {

    const [shops, setShops] = useState([])

    useState(() => {
        getAllStores().then((data) => {
            if (data.aerror) {
                console.log(data.error);
            } else {
                setShops(data)
            }
        })
    }, [])

    return (shops.length === 0 ? <div className="container" style={{ height: "70vh" }}><CommonLoading></CommonLoading></div>
        : (
            <div className="container">
                <div className="row mt-4">
                    <div className="container">
                        <div className="col-12">
                            <h2 className="mb-4">Shops</h2>
                            <div className="row">
                                {shops.map((shop, i) => (
                                    <ShopsCard key={i} shop={shop}></ShopsCard>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        ))
}

export default Shops
