import React from 'react'

const EmailVerified = () => {
    return (
        <div style={{height: "300px"}} className='container mt-4 mb-4'>
            <h1>Your Email has been verified.</h1>
            <br />
            <h3>You can go ahead and login on the admin panel</h3>
            <h4>Keep an eye on your email 👀</h4>
        </div>

    )
}

export default EmailVerified