import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { signup } from '../auth';
import app from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Add } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify'

const Signup = () => {

    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        city: "",
    });

    const [file, setFile] = useState({});
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const storage = getStorage(app);

    const handleChange = name => event => {
        setUser({ ...user, [name]: event.target.value });
    }

    // const uploadAllPhotos = async (photo) => {
    //     await uploadImageAsPromise(photo)
    //         .then((res) => {
    //             setPhoto(res.toString())
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            acceptedFiles.map(file => {
                setFile(Object.assign(file, { preview: URL.createObjectURL(file) }))
            })
            // handleImageDrop(acceptedFiles);
        }
    });

    const uploadImageAsPromise = async (photo) => {
        return new Promise((resolve, reject) => {

            const fileName = new Date().getTime() + photo.name
            const storageRef = ref(storage, fileName);
            const uploadTask = uploadBytesResumable(storageRef, photo)

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                        default:
                    }
                },
                (err) => {
                    console.log(err);
                    reject(err)
                },
                async () => {
                    await getDownloadURL(uploadTask.snapshot.ref)
                        .then((downloadURL) => {
                            resolve(downloadURL);
                        });
                }
            );
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        toast.dismiss()
        toast.info("Setting up your shop")
        await signup({ ...user })
                .then(data => {
                    if (data.error) {
                        setError(data.error)
                        toast.dismiss()
                        toast.error(data.error)
                    }
                    else {
                        console.log(data);
                        window.location.href = "/";
                    }
                })
    }

    const showError = () => {
        return <div classNameName="alert alert-danger" style={{ display: error ? '' : "None " }}>
            {error}
        </div>
    }

    const showSuccess = () => {
        return <div classNameName="alert alert-info" style={{ display: success ? '' : "None " }}>
            New account is created. Please <Link to="/signin">sign in</Link>
        </div>
    }

    const thumb = {
        display: 'inline-flex',
        marginBottom: 8,
        marginRight: 8,
        padding: 4,
        boxSizing: 'border-box'
    };

    const closeBtn = {
        position: "absolute",
        top: "2px",
        right: "2px",
        zIndex: "100"
    }

    const thumbs = () => (
        <div style={thumb} key={file.name}>
            <img
                className="rounded-circle img-fluid"
                src={file.preview}
                style={{ minWidth: "100px", width: "140px" }}
                alt=""
            />
        </div>
    )


    // const signUpForm = () => {
    //     return <form action="" onSubmit={handleSubmit}>
    //         <div classNameName="form-group">
    //             <label classNameName='text-muted'>Name</label>
    //             <input onChange={handleChange('name')} type="text" classNameName="form-control" value={name}/>
    //         </div>

    //         <div classNameName="form-group">
    //             <label classNameName='text-muted'>Email</label>
    //             <input onChange={handleChange('email')} type="email" classNameName="form-control" value={email}/>
    //         </div>

    //         <div classNameName="form-group">
    //             <label classNameName='text-muted'>Password</label>
    //             <input onChange={handleChange('password')} type="password" classNameName="form-control" value={password}/>
    //         </div>

    //         <button onClick={handleSubmit} classNameName="btn bt-primary" type="submit">Submit</button>
    //     </form>
    // }
    // return (
    //     <div>
    //         <Layout title="Sign Up" description="Sign Up to React E-commerce App" classNameName="container">
    //             {showSuccess()}
    //             {showError()}
    //             {signUpForm()}
    //         </Layout>
    //     </div>
    // )'

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: 'white' }}>
            <div className="col-lg-12 col-xl-11">
                <div className="text-black">
                    <div className="card-body p-md-5">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>
                                {showSuccess()}
                                {showError()}
                                {/* <label className="form-label" htmlFor="">Store Image</label>
                                <div className="mx-1 mx-md-4 form-group mt-3 d-flex flex-direction-row align-items-center ">
                                    <div {...getRootProps({ className: 'dropzone' })} className="d-flex border border-dark align-items-center justify-content-center mb-4 col col-lg-4 col-4" style={{ border: "1px dotted gray", height: "140px" }}>
                                        <input {...getInputProps()} />
                                        <Add style={{ fontSize: '3rem' }} />
                                    </div>
                                    {thumbs()}
                                </div> */}
                                <form className="mx-1 mx-md-4">
                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fa-solid fa-store"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <input onChange={handleChange('name')} type="text" id="form3Example1c" className="form-control" />
                                            <label className="form-label" htmlFor="form3Example1c">Store Name*</label>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i class="fa-solid fa-align-left"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <textarea rows={5} cols={40} required onChange={handleChange('about')} type="text" id="form3Example3c" className="form-control" />
                                            <label className="form-label" htmlFor="form3Example3c">About the shop*</label>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <input required onChange={handleChange('email')} type="email" id="form3Example3c" className="form-control" />
                                            <label className="form-label" htmlFor="form3Example3c">Your Email*</label>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <input required onChange={handleChange('password')} type="password" id="form3Example4c" className="form-control" />
                                            <label className="form-label" htmlFor="form3Example4c">Password*</label>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <input required onChange={handleChange('city')} type="text" id="form3Example4c" className="form-control" />
                                            <label className="form-label" htmlFor="form3Example4c">City</label>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <input onChange={handleChange('address')} type="text" id="form3Example4cd" className="form-control" placeholder="optional" />
                                            <label className="form-label" htmlFor="form3Example4cd">Address</label>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <input onChange={handleChange('link')} type="text" id="form3Example4cd" className="form-control" placeholder="optional" />
                                            <label className="form-label" htmlFor="form3Example4cd">Store link</label>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <input onChange={handleChange('openTiming')} type="text" id="form3Example4cd" className="form-control" placeholder="optional" />
                                            <label className="form-label" htmlFor="form3Example4cd">open timings</label>
                                        </div>
                                    </div>

                                    <div className="form-check d-flex-row justify-content-center align-items-center mb-5">
                                        <input required
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="form2Example3c"
                                        />
                                        <label className="form-check-label" htmlFor="form2Example3">
                                            I agree all statements in <a href="#!">Terms of service</a>
                                        </label>
                                    </div>

                                    <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                        <button onClick={handleSubmit} type="submit" className="btn btn-primary btn-lg">Register</button>
                                    </div>

                                </form>

                            </div>
                            <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp" className="img-fluid" alt="Sample image" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup
