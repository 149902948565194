import { Facebook, Instagram, MailOutline, Phone, Pinterest, Room, Twitter } from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'
import { mobile } from '../responsive';

const Container = styled.div`
display:flex;
margin: 15px 15px;
${mobile({
    flexDirection: 'column'
})}

`
const Left = styled.div`
flex:1;
display:flex;
flex-direction:column;
${mobile({
    marginLeft: "10px",
    padding: "20px 5px"
})}
`;

const Logo = styled.h1``;

const Desc = styled.p`
margin:20px 0px;
`;
const SocialContainer = styled.div`
display:flex;

`;
const SocialIcon = styled.div`
width:40px;
height:40px;
border-radius:50%;
color:white;
background-color:#${props => props.color};
display:flex;
align-items:center;
justify-content:center;
margin-right:20px;
`;

const Center = styled.div`
flex:1;
padding:0px;
margin-left:10px;
${mobile({
    marginLeft: "10px",
    padding: "20px 5px"
})}

`
const Title = styled.h3`
margin-bottom:18px;
`
const List = styled.ul`
margin:0;
padding:0;
list-style:none;
display:flex;
flex-wrap:wrap;
`

const ListItem = styled.li`
width:50%;
margin-bottom:10px;
`

const Right = styled.div`flex:1
${'' /* ${mobile({
    backgroundColor:"#fff8f8"
})} */}
${mobile({
    marginLeft: "10px",
    padding: "20px 5px"
})}
`

const ContactItem = styled.div`
margin-bottom:20px;
display:flex;
align-items:center;
`;

const Payment = styled.img`
width:50%;
`

const Footer = () => {
    return (
        <Container class="bg-primary">
            <Left>
                <Logo>ProductJi</Logo>
                <Desc>
                    There are many variations of passages of Lorem Ipsum available, but
                    the majority have suffered alteration in some form, by injected
                    humour, or randomised words which don’t look even slightly believable.
                </Desc>
                <SocialContainer>
                    <SocialIcon color='3B5999'>
                        <Facebook />
                    </SocialIcon>
                    <SocialIcon color="E4405F">
                        <Instagram />
                    </SocialIcon>
                    <SocialIcon color="55ACEE">
                        <Twitter />
                    </SocialIcon>
                    <SocialIcon color="E60023">
                        <Pinterest />
                    </SocialIcon>
                </SocialContainer>
            </Left>
            <Center>
                <Title>Useful Links</Title>
                <List>
                    <ListItem>Home</ListItem>
                    <ListItem>Cart</ListItem>
                    <ListItem>Man Fashion</ListItem>
                    <ListItem>Woman Fashion</ListItem>
                    <ListItem>Accessories</ListItem>
                    <ListItem>My Account</ListItem>
                    <ListItem>Order Tracking</ListItem>
                    <ListItem>Wishlist</ListItem>
                    <ListItem>Wishlist</ListItem>
                    <ListItem>Terms</ListItem>
                </List>
            </Center>
            <Right>
                <Title>Contact</Title>
                <ContactItem>
                    <Room style={{ marginRight: "10px" }} /> 50/42 Sector-5, Pratap Nagar, Jaipur
                </ContactItem>
                <ContactItem>
                    <Phone style={{ marginRight: "10px" }} /> +91-9928005764 / +91-8828026302
                </ContactItem>
                <ContactItem>
                    <MailOutline style={{ marginRight: "10px" }} /> contact@productji.com
                </ContactItem>
            </Right>
        </Container>
    )
}

export default Footer
