import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { activateAccount } from '../auth'
import Layout from '../core/Layout'
const Verify = () => {

    const {activateToken} = useParams();

    console.log(activateToken);
  

    const handleSubmit = () => {
        activateAccount({activateToken});
    }
  

    const signInForm = () => {
        return <form action="">
            <div className="form-group">
                <label className='text-muted'>Email</label>
           
            </div>

            <div className="form-group">
                <label className='text-muted'>Password</label>
            </div>

            <button onClick={handleSubmit} className="btn bt-primary" type="submit">Submit</button>
        </form>
    }
    

    return (
        <div>
            <Layout title="Sign In" description="Sign In to Node React E-commerce App" className="container">
                {signInForm()}
              
            </Layout>
        </div>
    )
}

export default Verify
