import React from 'react'
import { BrowserRouter, Routes as Switch, Route } from 'react-router-dom'
import Home from './core/Home'
import Menu from './core/Menu'
import Product from './core/Product'
import Products from './core/Products'
import SearchResults from './core/SearchResults'
import Shop from './core/Shop'
import Shops from './core/Shops'
import Signin from './user/Signin'
import Signup from './user/Signup'
import Footer from './core/Footer'
import Verify from './user/Verify'
import PageNotFound from './core/PageNotFound'
import EmailVerified from "./auth/EmailVerified"

const Routes = () => {


  return (
    <BrowserRouter >
      <Menu />
      <Switch>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<SearchResults />} />
          {/* <Route exact path="/signin" element={<Signin />}/> */}
          <Route exact path="/signup" element={<Signup />}/>
          <Route exact path="/products" element={<Products />}/>
          <Route exact path="/shops" element={<Shops />}/>
          <Route exact path="/product/:productId" element={<Product  />} />
          <Route exact path="/shops/:shopId" element={<Shop />} />
          <Route exact path="/authenticate/activate/:activateToken" element={<Verify/>}/>
          <Route exact path="/email-verified" element={<EmailVerified/>}/>
          <Route path="*" element={<PageNotFound/>} />
      </Switch>
      <hr />
      <Footer/>
      
    </BrowserRouter>
  )
}

export default Routes
