import React from 'react'
const Layout = ({
    title = "Title",
    description = "Description",
    className,
    children
}) => {
    return (
        <div className='container mt-4'>
            <div className={className}>{children}</div>
        </div>
    )
}

export default Layout
