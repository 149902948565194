import React from 'react';
import { Link } from 'react-router-dom';


const PageNotFound= () =>{
	return(
        <>
        <div class="container" style={{height: '50vh'}}>
		<h1>404 Error</h1>
		<h1>Page Not Found</h1>
        <Link to="/">Click Here to go to homepage</Link>
	</div>
    </>
    )
}

export default PageNotFound;
