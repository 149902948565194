import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCategories, list } from './apiCore'
import Card from './Card'
import Layout from './Layout'
import Menu from './Menu'

const Search = () => {

    const navigate = useNavigate();

    const [data, setData] = useState({
        search: '',
    })

    const {search} = data

    const searchSubmit = (event) => {
        event.preventDefault();
        navigate({
            pathname: '/search',
            search: `?search=${search}`,
          });      
    }

    

    const handleChange = (name) => (event) => {
        setData({ ...data, [name]: event.target.value, searched: false })
    }

    const searchForm = () => {
        return <form onSubmit={searchSubmit} action="" className='mt-1'>
            <div className="d-flex justify-content-center align-items-center">
                <input type="search" className='form-control mx-4 my-2 pb-2' onChange={handleChange("search")} placeholder='Search' />
            </div>
        </form>
    }

    return (
        <div>
            <div className="">
                {searchForm()}
            </div>
        </div>
    )
}

export default Search
