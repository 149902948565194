import React from 'react'
import { Link } from 'react-router-dom'

const ShopsCard = ({ shop }) => {
    return (
        <div className="col-md-6 mb-3 col-lg-3">
            <Link to={`/shops/${shop._id}`} style={{color: "black",textDecoration: "none"}}>
                <div className="carrd" >
                    <div className="card-body" >
                        <div className="mb-3 d-flex justify-content-center" >
                            {shop?.photo ? <img src={shop.photo} alt={shop?.name} className="rounded-circle img-fluid" style={{ maxHeight: '200px', maxWidth: '100%' }} /> : <img src="https://cdn2.iconfinder.com/data/icons/facebook-ui-colored/48/JD-15-512.png" alt={shop?.name} className="rounded-circle img-fluid" style={{ maxHeight: '200px', maxWidth: '100%' }} />}
                        </div>
                        <div >
                            <h6>{shop?.name}</h6>
                            <p>{shop?.about?.substring(0, 50) + "..."}</p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ShopsCard